import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import Container from '../components/Container'
import Inner from '../components/Inner'
import LatestNews from '../components/LatestNews/LatestNews'
import Newsletter from '../components/Forms/Newsletter/Newsletter'
import InfoBox from '../components/InfoBox/InfoBox'
import { Heading2, Heading7 } from '../components/Typography/Typography'
import Layout from '../components/Layout'
import FlexContentBlocks from '../common/queries/FlexContentBlocks'
import SEO from '../components/Seo'
import { TRUNCATED_HERO_TYPES } from '../constants/truncatedHeroTypes'
import {
  replaceAtToSpan,
  newsletterSpacings,
  buildAction,
  bgTheme,
  generateHero,
} from '../common/utils'
import { BG_TYPES } from '../constants/bgTypes'
import mapNewsToList from '../common/queries/mapNewsToList'
import HTMLBody from '../components/HTMLBody'
import NewsListProvider from '../providers/NewsListProvider'

const CategoryTemplate = ({ pageContext, data }) => {
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  const { acf: category } = data.wp.category
  const { posts, newsUrlList } = pageContext
  const seoData = data.wp.category.seo
  let heroAction

  const {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  } = generateHero(category)

  if (category.heroFields.action.showAction !== null) {
    heroAction = buildAction(category.heroFields.action.action)
  }

  const contentBlocks = category.contentBlocks.content
  const newsletterBg = bgTheme(category.newsletterContainerBackground)
  const newsListBg = bgTheme(category.newsListBackground)
  const newsletterSpacing = newsletterSpacings(category.newsletterBoxMargins)
  const relatedNews = useMemo(() => mapNewsToList(posts), [posts])

  return (
    <NewsListProvider list={newsUrlList}>
      <Layout>
        <SEO title={seoData?.title} data={seoData} />
        <Container
          pb={['', '1.875rem', '6rem']}
          bg={[heroBgColorMobile, '', heroBgColorDesktop]}
          color={[heroColorMobile, '', heroColorDesktop]}
        >
          <TruncatedHero
            heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
            heroImages={heroImages}
            heroSlantType={slantDirection || 'right'}
            opacityImg={0.91}
            gradient={hasBgGradient ? bgGradient : null}
            lightGradient={heroBgColorMobile === BG_TYPES.white}
            long={isHeroHigh}
          >
            <Container mt={['-4rem', '']} mb={['', '-2rem']}>
              <InfoBox
                title={
                  <Heading2
                    as="h1"
                    dangerouslySetInnerHTML={{
                      __html: replaceAtToSpan(category.heroFields.infoboxTitle),
                    }}
                  />
                }
                content={
                  <HTMLBody
                    opacity="1"
                    html={category.heroFields.infoboxContent}
                  />
                }
                action={category.heroFields.action.showAction && heroAction}
                wide
                bgOpacity={infoBoxOpacity}
                bg={infoBoxBg}
              />
            </Container>
          </TruncatedHero>
        </Container>
        <FlexContentBlocks contentBlocks={contentBlocks} pageData={data} />
        <Container
          pt={newsletterSpacing}
          pb={newsletterSpacing}
          bg={newsletterBg}
        >
          <Inner pl={['0', '1.875rem']} pr={['0', '1.875rem']}>
            <Newsletter />
          </Inner>
        </Container>
        {relatedNews && (
          <Container
            inView={inView}
            pt={['1.875rem', '', '3.375rem']}
            pb={['1.875rem', '3.375rem']}
            bg={newsListBg}
            color={
              newsListBg === BG_TYPES.white || newsListBg === BG_TYPES.lightGrey
                ? 'black'
                : 'white'
            }
          >
            <Inner>
              <Container mb={['2.5rem', '3.375rem']} className="uiAnimBottom">
                <Heading7 as="h3" textAlign="center">
                  Related News
                </Heading7>
              </Container>

              <div ref={ref}>
                {inView ? (
                  <LatestNews news={relatedNews} hasLoadMore anim={false} />
                ) : null}
              </div>
            </Inner>
          </Container>
        )}
      </Layout>
    </NewsListProvider>
  )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query($categoryId: ID!, $categoryDatabaseId: Int) {
    wp {
      models(where: { categoryId: $categoryDatabaseId }, first: 50) {
        edges {
          node {
            id
            title
            slug
            featuredImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            acf {
              ...modelDetailsFragment
            }
          }
        }
      }
      category(id: $categoryId) {
        name
        slug
        seo {
          ...seoFragment
        }
        acf {
          newsletterBoxMargins
          newsletterContainerBackground
          newsListBackground
          heroFields {
            slantDirection
            bgGradient
            boxBackgroundOpacity
            boxBackgroundColor
            infoboxTitle
            infoboxContent
            heroHeight
            bgDesktop
            bgMobile
            backgroundDesktop {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundMobile {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_City_Term {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          contentBlocks {
            content {
              ... on wp_Category_Acf_ContentBlocks_Content_PictureAndContentBox {
                fieldGroupName
                contentColTheme
                imageOrVideo
                cincopaVideoId
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                negativeMargin
                pictureBoxType
                withInner
                title
                addSpacings
                content
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Category_Acf_ContentBlocks_Content_Callout {
                fieldGroupName
                bg
                pbPt
                title
                content
                blockStyle
                hasAdditionalSpacingOnBottom
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Category_Acf_ContentBlocks_Content_ModelsSlider {
                fieldGroupName
                bg
                isAlt
              }
              ... on wp_Category_Acf_ContentBlocks_Content_OtherModelsSlider {
                fieldGroupName
                bg
                type
              }
              ... on wp_Category_Acf_ContentBlocks_Content_GallerySlider {
                fieldGroupName
                bg
                images {
                  image {
                    mediaItemUrl
                    imageFile {
                      childImageSharp {
                        fixed(height: 333) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                  action {
                    hoverLabel
                    showAction
                    action {
                      internalOrExternal
                      external {
                        target
                        title
                        url
                      }
                      internal {
                        title
                        target {
                          ... on wp_Post {
                            contentType {
                              node {
                                name
                              }
                            }
                            slug
                          }
                          ... on wp_Page {
                            contentType {
                              node {
                                name
                              }
                            }
                            slug
                          }
                          ... on wp_Model {
                            contentType {
                              node {
                                name
                              }
                            }
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
